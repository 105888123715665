import { useModeHistoryPushCallback } from '../components/ModeRouter/ModeRouter'
import { useDiningOptions } from './useDiningOptions'
import { useCallback } from 'react'

export const useRedirectToFulfillment = () => {
  const { refetch } = useDiningOptions()
  const modeHistoryPush = useModeHistoryPushCallback({
    mode: 'fulfillment'
  })

  return useCallback(() => {
    refetch()
    modeHistoryPush()
  }, [refetch, modeHistoryPush])
}
