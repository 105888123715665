import React from 'react'
import { ErrorComponent } from '@local/do-secundo-error'
import { UnavailableModal, TimeChangedModal } from './CheckoutErrorModals'
import { useModeHistoryPushCallback } from '../ModeRouter/ModeRouter'
import { getWarnings, Warning } from '../Cart/Validation/CartValidation'
import { useRestaurant } from '@local/do-secundo-restaurant-provider/src'
import { Cart } from '../../types/cart'
import { useRedirectToFulfillment } from '../../hooks/useRedirectToFullfilment'

export const ORDER_TIME_UNAVAILABLE = 'ORDER_TIME_UNAVAILABLE'
export const ORDER_TIME_CHANGED = 'ORDER_TIME_CHANGED'
export const INVALID_CREDIT_CARD = 'INVALID_CREDIT_CARD'
export const UNSUPPORTED_PROMO_CODE = 'UNSUPPORTED_PROMO_CODE'

interface Error {
  message?: string
  code?: string
  cartUpdatedCode?: string
  retryMessage?: string
  moreInfo?: { header: string; message: string }
}

/*
 * Temporary until we get structured data back. Parse out any
 * raw messages and format to be more human readable for the guest.
 */
const formatErrorMsg = (errorToFormat: string | Error): string | Error => {
  const error =
    typeof errorToFormat === 'string'
      ? { message: errorToFormat }
      : errorToFormat

  if (!error.message) {
    return 'Uh oh, something went wrong!'
  }

  // Delivery provider errors
  if (error.message.match(/^\[UnavailabilityReason/)) {
    // check for multiple errors
    const errorsRaw = error.message.match(/^\[(.*)\],?$/)?.[1] || ''
    const errors = errorsRaw.split(/\),\s*/)

    if (errors) {
      const errorMsgs = errors.map((msg) => {
        // put all matching errors codes here
        if (msg.match(/reasonCode=provider\.phone_number\.invalid/)) {
          return 'The phone number provided is not recognized.'
        } else if (
          msg.match(
            /(provider\.unknown|toast\.3pd_disabled|toast\.provider_disabled|toast\.invalid_config|toast\.internal_error|toast\.provider_error|restaurant\.provider_disabled|provider\.pickup_address\.invalid|provider\.pickup_address\.unserved)/
          )
        ) {
          return 'Delivery is currently unavailable.'
        } else if (msg.match(/provider\.dropoff_address\.invalid/)) {
          return 'The address provided is not recognized.'
        } else if (msg.match(/provider\.dropoff_address\.unserved/)) {
          return 'The address provided is not in range.'
        } else if (msg.match(/provider\.order_value\.too_small/)) {
          return 'Order total has not met the minimum for delivery.'
        } else if (msg.match(/provider\.order_value\.too_big/)) {
          return 'Order total is above the maximum allowed for delivery.'
        } else if (msg.match(/provider\.unavailable_at_time/)) {
          return 'Delivery is unavailable for your selected time.'
        } else if (msg.match(/provider\.invalid_tip_amount/)) {
          return msg.match(/reasonMsg=([^&]+)\)/)?.[1]
        }

        // If we can't parse return empty string. If the error is empty we will
        // just display the raw error message.
        return ''
      })
      const msg = errorMsgs ? errorMsgs.join(' ') : ''
      const trimmedMsg = msg.trim()
      if (msg.toLowerCase().match(/tip/)) {
        error.message = trimmedMsg
        error.retryMessage = 'Please adjust tip amount and try again.'
        return error
      } else if (trimmedMsg) {
        error.message = trimmedMsg
        return error
      }
    }
  } else if (error.code === 'CRITICAL_ERROR') {
    error.message = "We're having trouble placing the order."
    error.retryMessage = 'Submit order to try again.'
  } else if (error.code === UNSUPPORTED_PROMO_CODE) {
    error.message = 'You have already redeemed this one-time promotion.'
    error.retryMessage = 'Please remove the promo code and try again.'
  } else if (error.code === INVALID_CREDIT_CARD) {
    error.message = `There was a problem with your card. 
      You have not been charged, but may notice a temporary authorization on your statement.`
    error.moreInfo = {
      header: `There was a problem with your card`,
      message: `Please check the card information you entered. 
        If your card was denied due to zip code or CVV/security code not matching the information your bank has on file, 
        your bank may show a hold for the amount of this order on your statement. Your card has not been charged and you 
        should reach out to your bank directly if the held amount is not removed in 3-4 business days.`
    }
    error.retryMessage = 'Review card information entered above and try again.'
  }
  return error
}

interface Props {
  error: string | Error
  loading?: boolean
  onSubmit: any
  cart: Cart
}

export const CheckoutError = ({ error, loading, onSubmit, cart }: Props) => {
  const { restaurantInfo } = useRestaurant()

  const behavior =
    cart.diningOptionBehavior === 'DELIVERY' ? 'delivery' : 'pickup'

  const WARNINGS = getWarnings(
    restaurantInfo?.name,
    behavior,
    useRedirectToFulfillment()
  )
  if (error['messageKey'] && WARNINGS[error['messageKey']]) {
    return (
      <Warning
        messageKey={error['messageKey']}
        message={error['message']}
        index={0}
        WARNINGS={WARNINGS}
      />
    )
  }

  error = formatErrorMsg(error)
  if (!error) return null
  const code = (error as Error)?.cartUpdatedCode || (error as Error)?.code
  if (code === ORDER_TIME_UNAVAILABLE) {
    return <UnavailableModal />
  }
  if (code === ORDER_TIME_CHANGED) {
    return <TimeChangedModal onSubmit={onSubmit} loading={loading} />
  }

  return loading ? null : <ErrorComponent error={error} isActionable={false} />
}
